import { FIELDS, INVALID_CONTRACT } from '../constants/profile';
import { AIRLINE_PROVIDERS } from '../constants/airline';

import { getMoment, isMoment, momentObject, isBetweenDate } from './formatDate';

const THREE_YEARS_IN_MONTHS = 36;
const TRESH_DATE = 1000;

const requiredField = value => value && value !== '';

const requiredNumber = value => !(value.indexOf(',') !== -1 || value.indexOf('.') !== -1);

const requiredValue = value => value;

const isNotNegativeNumber = (value) => {
  if (typeof value === 'string' && !value.length) return false;

  const num = Number(value);

  return (!isNaN(num) && num >= 0);
};

const requiredLength = value => value.length > 0;

const requiredSelect = (value, select) => select.some(item => item.cost === Number(value));

const requiredSelectIata = (value, select) => {
  const numericValue = Number(value);

  return isNaN(numericValue)
    ? select.some(item => item.iata === value)
    : select.some(item => item.cost === numericValue);
};

const findAviaCost = ({ Iata }, contract) =>
  contract.find(({ cost, iata }) =>
    Iata === AIRLINE_PROVIDERS.S7
      ? iata === Iata
      : cost === Iata
  );

const requiredDate = value => isMoment(value) && value.isValid();

const checkValidationPeriods = (period) => {
  const isCrossingDates = !!period.find(({ StartLifeTime, EndLifeTime }, index) => (
    period.find(({ StartLifeTime: start, EndLifeTime: end }, ind) => {
      const startLifeTimeMoment = isMoment(StartLifeTime) ? StartLifeTime : momentObject(StartLifeTime);
      const endLifeTimeMoment = isMoment(EndLifeTime) ? EndLifeTime : momentObject(EndLifeTime);
      const startMoment = isMoment(start) ? start : momentObject(start);
      const endMoment = isMoment(end) ? end : momentObject(end);

      return ind !== index &&
        (isBetweenDate(startLifeTimeMoment, startMoment, endMoment, 'day', '[]') ||
        isBetweenDate(endLifeTimeMoment, startMoment, endMoment, 'day', '[]'));
    })
  ));

  return isCrossingDates ? INVALID_CONTRACT.CROSSING_DATES_PERIODS : '';
};

const checkValidationDate = (validationObj, field, value) => {
  const validator = validationObj[field];
  const validationFn = validator.fn;

  if (!validationFn(value)) {
    return validator.msg;
  }

  if (getMoment().diff(momentObject(value), 'months') > THREE_YEARS_IN_MONTHS) {
    return INVALID_CONTRACT.INVALID_START_DATE;
  }

  if (momentObject(value).year() < TRESH_DATE) {
    return INVALID_CONTRACT.INVALID_DATE;
  }

  return '';
};

const checkValidation = (validationObj, field, value) => {
  if (field === FIELDS.PERIODS_RATE) {
    const resultValidation = value.map(({ Price }) => ({
      Price: !validationObj.Price.fn(Price) ? validationObj.Price.msg : '',
    }));

    return resultValidation;
  }

  const validator = validationObj[field];
  const validationFn = validator.fn;

  return !validationFn(value) ? validator.msg : '';
};

export {
  requiredField,
  requiredNumber,
  requiredValue,
  requiredLength,
  requiredSelect,
  checkValidation,
  requiredDate,
  isNotNegativeNumber,
  checkValidationDate,
  checkValidationPeriods,
  requiredSelectIata,
  findAviaCost,
};
