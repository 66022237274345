const filterOnlyNumberArray = (array) => {
  if (!array) return null;

  const filteredArray = array.filter(value => !!value && !!Number(value) && Number(value) > 0);

  if (!filteredArray.length) return null;

  return filteredArray;
};

const filterArrayFromEmpty = (array) => {
  const filteredArray = array.filter(value => !!value);

  if (!filteredArray.length) return null;

  return filteredArray;
};

const arrayPropsToString = (array) => {
  if (!array || !array.length) return array;

  return array.map(item => String(item));
};

export {
  filterOnlyNumberArray,
  filterArrayFromEmpty,
  arrayPropsToString,
};
