import { diffDays, momentObject, formatDate } from '../../bi/utils/formatDate';
import { DEFAULTTIME } from '../../constants/time';

import { SERVICETYPE } from '../../bi/constants/serviceType';
import { VATVALUE, MEAL_LIST_TYPE } from '../../bi/constants/hotels';

const prepareDates = (time, date) => time ?
  `${date.split('T')[0]}T${time}:00` :
  date;

const prepareApproveData = items => ({
  Steps: [{ Approvers: items }],
  IsSimple: true,
});

const prepareDataCreate = (item) => {
  const { JsonData, CompanyId, ProjectId, DepartmentId, ServiceType, Meal, UserAnalytics } = item;
  const data = JSON.parse(JsonData);
  const { EmployeeIds: Employees } = data;
  let jsonData = {};

  switch (ServiceType) {
    case SERVICETYPE.HOTEL: {
      const {
        Hotel,
        Room,
        PriceDetails,
        GuestCount,
        CheckinDate,
        CheckoutDate,
        ProviderName,
        CustomCheckInDate,
        CustomCheckOutDate,
        BreakfastName,
      } = data;

      const amenties = {
        HasInternet: false,
        Internet: 'Undefined',
        Bedding: false,
        BeddingType: 'Undefined',
        HasCancellation: false,
        CancellationInfo: Room.FreeCancellation,
        HasBath: false,
        BathType: 'Undefined',
        AdditionalMeals: [],
        HasMeal: Meal.Include,
        MealType: 'Undefined',
        MealName: BreakfastName,
        CancellationPenalties: Room.CancellationPenalties,
        Meal,
      };
      const price = {
        Base: PriceDetails.Base,
        Commission: PriceDetails.commission,
        HasVAT: PriceDetails.hasVat,
        EarlyCheckIn: PriceDetails.EarlyCheckIn,
        LateCheckOut: PriceDetails.LateCheckOut,
      };

      jsonData = {
        hotel: Hotel,
        room: {
          Name: Room.Category,
          Price: price,
          Amenities: amenties,
          FreeCancellation: Room.FreeCancellation,
        },
        GuestsCount: Number(GuestCount),
        checkin: CheckinDate,
        checkout: CheckoutDate,
        Rate: {
          CheckinDate: prepareDates(Hotel.CheckinTime, CheckinDate),
          CheckoutDate: prepareDates(Hotel.CheckoutTime, CheckoutDate),
          GuestsCount: Number(GuestCount),
          Price: price,
          RoomName: Room.Category,
          RoomGroupName: Room.Category,
          ProviderName,
          Amenities: amenties,
          Description: '',
          CustomCheckInDate,
          CustomCheckOutDate,
        },
      };

      break;
    }
  }

  return {
    CompanyId,
    ProjectId: ProjectId || 0,
    DepartmentId: DepartmentId || 0,
    ServiceType,
    Employees,
    UserAnalytics,
    Data: JSON.stringify(jsonData),
    Reason: data.Reason,
  };
};

const prepareDataEdit = (item, departments, isCreateTrip) => {
  switch (item.ServiceType) {
    case SERVICETYPE.HOTEL: {
      const data = JSON.parse(item.Data);
      const employeesList = item.Employees.map(({ Employee }) => Employee);
      const diff = diffDays(momentObject(data.checkin), momentObject(data.checkout));
      const priceDetails = {
        ...data.room.Price,
        commission: data.room.Price.Commission,
      };
      const priceDetailsByType = isCreateTrip ? priceDetails : {
        ...priceDetails,
        hasVat: data.room.Price.HasVAT ? VATVALUE.WITH : VATVALUE.WITHOUT,
      };

      return {
        ...item,
        JsonData: {
          ...data,
          GuestCount: data.GuestsCount,
          NightsCount: diff,
          Room: {
            ...data.room,
            FreeCancellation: data.room.Amenities.CancellationInfo,
            Category: data.room.Name,
          },
          Hotel: {
            ...data.hotel,
            CheckinTime: data.Rate.CustomCheckInDate ?
              formatDate(data.Rate.CheckinDate, DEFAULTTIME) :
              data.hotel.CheckinTime,
            CheckoutTime: data.Rate.CustomCheckOutDate ?
              formatDate(data.Rate.CheckoutDate, DEFAULTTIME) :
              data.hotel.CheckoutTime,
          },
          CheckinDate: data.checkin,
          CheckoutDate: data.checkout,
          ProviderName: data.Rate.ProviderName,
          CustomCheckInDate: data.Rate.CustomCheckInDate,
          CustomCheckOutDate: data.Rate.CustomCheckOutDate,
          Breakfast: data.room.Amenities.HasMeal,
        },
        CancellationPenalties: data.Rate.Amenities.CancellationPenalties.length ?
          data.Rate.Amenities.CancellationPenalties :
          null,
        hotelName: {
          label: data.hotel.Name || '',
          selected: {},
          suggests: [],
        },
        CustomHotelName: data.hotel.Name,
        errors: {
          'Hotel.CheckinTime': '',
          'Hotel.CheckoutTime': '',
        },
        EmployeesList: employeesList.length ? employeesList : [{}],
        employeesList: [],
        PriceDetails: priceDetailsByType,
        Departments: departments || [],
        DepartmentId: item.EmployeeCartItems.length ? item.EmployeeCartItems[0].DepartmentId : 0,
        isEdit: true,
        CountryCode: '',
        CartId: item.CartId,
        EmployeeId: item.EmployeeCartItems.length ? item.EmployeeCartItems[0].EmployeeId : 0,
        isNewTrip: true,
        CurrencyData: { Inclusive: 0, MFInCurrency: 0 },
        InternalVat: {
          VatReady: null,
          VatInfo: null,
        },
        Meal: MEAL_LIST_TYPE.find(({ value }) => value === data.room.Amenities.Meal.Category).list,
        internalVatDialogValue: {
          RackRate: 0,
          Rate: '',
          Amount: 0,
        },
      };
    }

    default: return {};
  }
};

const prepareUsavedItem = (item, tripToEdit, departments) => {
  switch (item.ServiceType) {
    case SERVICETYPE.HOTEL: {
      const data = JSON.parse(item.JsonData);

      return {
        ...tripToEdit,
        JsonData: data,
        Employees: [],
        EmployeeId: !tripToEdit.CompanyId ? null : data.EmployeeIds[0].Id,
        employeesList: [],
        Departments: departments || [],
        isEdit: true,
        isCreateCart: true,
        hotelName: {
          label: data.Hotel.Name || '',
          selected: {},
          suggests: [],
        },
        CustomHotelName: data.Hotel.Name,
        errors: {
          'Hotel.CheckinTime': '',
          'Hotel.CheckoutTime': '',
        },
        PriceDetails: {
          ...item.PriceDetails,
          hasVat: item.PriceDetails.hasVat ? VATVALUE.WITH : VATVALUE.WITHOUT,
        },
        CountryCode: '',
        CancellationPenalties: data.Room.CancellationPenalties && data.Room.CancellationPenalties.length ?
          data.Room.CancellationPenalties :
          null,
        isNewTrip: true,
        InternalVat: item.InternalVat,
        CurrencyData: { Inclusive: 0, MFInCurrency: 0 },
        internalVatDialogValue: {
          RackRate: 0,
          Rate: '',
          Amount: 0,
        },
        Meal: MEAL_LIST_TYPE.find(({ value }) => value === item.Meal.Category).list,
      };
    }

    default: return {};
  }
};

const prepareDataCreateTrip = (item) => {
  switch (item.ServiceType) {
    case SERVICETYPE.HOTEL: {
      const data = JSON.parse(item.JsonData);

      const jsonData = {
        Hotel: {
          ...data.Hotel,
          ClassificatorId: data.hotel.ClassificatorId || null,
        },
        CheckinDate: data.checkin,
        CheckoutDate: data.checkout,
        NightsCount: data.NightsCount,
        NightsPrice: data.NightsPrice,
        EmployeeIds: data.EmployeeIds,
        ReservationDate: data.ReservationDate,
        ReservationNumber: data.ReservationNumber,
        Room: {
          Category: data.Room.Category,
          FreeCancellation: data.Room.FreeCancellation,
          CancellationPenalties: data.Room.CancellationPenalties,
        },
        ProviderName: data.ProviderName,
        Breakfast: data.Breakfast,
        ServiceType: item.ServiceType,
        PriceDetails: data.PriceDetails,
        GuestCount: data.GuestCount,
        CustomCheckInDate: data.CustomCheckInDate,
        CustomCheckOutDate: data.CustomCheckOutDate,
      };

      return {
        ...item,
        JsonData: JSON.stringify(jsonData),
      };
    }

    default: return {};
  }
};

const validateIntercomLink = (value) => {
  const regexLink = /(?<=\/conversation\/)\d+/;

  return regexLink.test(value);
};

export {
  prepareApproveData,
  prepareDataCreate,
  prepareDataEdit,
  prepareUsavedItem,
  prepareDataCreateTrip,
  validateIntercomLink,
};
