import { minutesToMoment, momentObject } from '../utils/formatDate';
import lodashReplaces from '../utils/lodashReplaces';
import { FIELDS, FUNDSFIELDS, MODELS } from '../constants/trips';
import AIRPROVIDERS, { AMADEUS_ORDER_IDS } from '../constants/arline';
import {
  AVIA_COMPANY_CONTRACT_S7,
  DEFAULT_AVIA_COMPANY_CONTRACT,
  AVIA_COMPANY_CONTRACT_MILE,
  AVIA_COMPANY_CONTRACT,
 } from '../constants/profile';
import { AIRLINE_PROVIDERS } from '../constants/airline';

const DELETETYPES = {
  TICKET: 'ticket',
  ROUTE: 'route',
  SEGMENT: 'segment',
};

const DISCOUNT_VALUES = {
  DISCOUNT_S7: 2, // Скидка для компании S7
  DISCOUNT_DEFAULT: -1, // Значение по умолчанию (нет скидки)
  DISCOUNT_FOR_S7_CONTRACT: 1,  // Скидка для контракта S7
  DISCOUNT_MILE: 0, // Скидка по милям
};

const updateVatDetails = (ticketInd, rate, field, value, state) => {
  const clonedState = { ...state };

  const indexOfVatElement = clonedState.JsonData[ticketInd].VatDetails.findIndex(({ Rate }) => Rate === rate);
  if (typeof indexOfVatElement === 'undefined') return clonedState;

  clonedState.JsonData[ticketInd].VatDetails[indexOfVatElement][field] = value;

  return clonedState;
};

const deletePart = (state, { type, ticketInd, routeInd, segmentInd }) => {
  const clonedState = { ...state };

  switch (type) {
    case DELETETYPES.TICKET:
      clonedState.JsonData.splice(ticketInd, 1);
      break;
    case DELETETYPES.ROUTE:
      clonedState.JsonData[ticketInd].Routes.splice(routeInd, 1);
      break;
    case DELETETYPES.SEGMENT:
      clonedState.JsonData[ticketInd].Routes[routeInd].Segments.splice(segmentInd, 1);
      break;
  }

  return clonedState;
};

const prepareVatDetails = (JsonData) => {
  const clonedJsonData = [...JsonData];

  return clonedJsonData.map((ticket) => {
    if (ticket.VatDetails.length === 1 && ticket.VatDetails[0].Rate === 0) return ticket;

    const updatedVatDetails = ticket.VatDetails.reduce((arr, { Rate, Amount }) => {
      if (!Amount) return arr;

      const updatedAmount = !Amount ? 0 : Amount;

      return [...arr, { Rate, Amount: updatedAmount }];
    }, []);

    return {
      ...ticket,
      VatDetails: updatedVatDetails,
    };
  });
};

const clearVatDetails = (JsonData) => {
  const clonedJsonData = [...JsonData];

  return clonedJsonData.map((ticket) => {
    const isVatEmpty = ticket.VatDetails.every(({ Amount }) => !Amount);

    if (!isVatEmpty) return ticket;

    return {
      ...ticket,
      VatDetails: MODELS.VatDetails0.map(i => ({ ...i })),
    };
  });
};

const preparedPriceDetails = (JsonData, changedBase) => JsonData.map((item) => {
  const { PriceDetails } = item;
  const { Tax, Taxes, Fee, Commission, Base } = PriceDetails;
  const base = changedBase ? parseFloat(Base, 10) || 0 : Base;
  const taxes = changedBase ? parseFloat(Taxes, 10) : 0;

  return {
    ...item,
    PriceDetails: {
      ...PriceDetails,
      Base: base,
      Tax: parseFloat(Tax, 10) || 0,
      Taxes: taxes,
      Fee: parseFloat(Fee, 10) || 0,
      Commission: parseFloat(Commission, 10) || 0,
    },
  };
});

const prepareFunds = (item) => {
  if (!item.Enabled) return [];

  const clearItem = lodashReplaces.omit(item, FIELDS.ENABLED);
  Object.keys(clearItem).forEach((key) => {
    if (!clearItem[key]) {
      clearItem[key] = key === FUNDSFIELDS.DESCRIPTION ? '' : 0;
    }
  });

  return [clearItem];
};

const setRoutesDurationAndTime = Routes => Routes.map((route) => {
  const newRoute = { ...route };
  const durationMoment = minutesToMoment(route.Duration);

  const segments = route.Segments.map((segment) => {
    const newSegment = { ...segment };

    const departureDateMoment = momentObject(segment.DepartureDate);
    const arrivalDateMoment = momentObject(segment.ArrivalDate);
    newSegment.DepartureDate = departureDateMoment;
    newSegment.ArrivalDate = arrivalDateMoment;
    newSegment.DepartureTime = departureDateMoment;
    newSegment.ArrivalTime = arrivalDateMoment;

    return newSegment;
  });

  newRoute.Segments = segments;
  newRoute.Duration = durationMoment;

  return newRoute;
});

const getAvia = (discountValue, iata) => {
  switch (discountValue) {
    case DISCOUNT_VALUES.DISCOUNT_S7:
      return AVIA_COMPANY_CONTRACT_S7;

    case DISCOUNT_VALUES.DISCOUNT_DEFAULT:
      return DEFAULT_AVIA_COMPANY_CONTRACT;

    case DISCOUNT_VALUES.DISCOUNT_FOR_S7_CONTRACT:
      return iata === AIRLINE_PROVIDERS.S7
        ? DEFAULT_AVIA_COMPANY_CONTRACT
        : AVIA_COMPANY_CONTRACT;

    case DISCOUNT_VALUES.DISCOUNT_MILE:
      return AVIA_COMPANY_CONTRACT_MILE;

    default:
      return AVIA_COMPANY_CONTRACT;
  }
};

const selectObj = ({ IsDiscount }) => {
  if (IsDiscount === 0) return AVIA_COMPANY_CONTRACT_MILE;

  if (IsDiscount === 2) return AVIA_COMPANY_CONTRACT_S7;

  return AVIA_COMPANY_CONTRACT;
};

const isShowTaxes = ({ ProviderName, ProviderInfo }) => {
  const isAmadeus = ProviderName === AIRPROVIDERS.AMADEUS.TYPE;
  const selectAmadeusOrderId = Object.values(AMADEUS_ORDER_IDS)
    .includes(ProviderInfo.OfficeId);

  return ProviderName === AIRPROVIDERS.ETS.TYPE ||
    ProviderName === AIRPROVIDERS.AVIACENTR.TYPE ||
    ProviderName === AIRPROVIDERS.PORTBILET.TYPE ||
    (isAmadeus && ProviderInfo && selectAmadeusOrderId);
};

const isShowTaxesClue = ({ ProviderName }) => ProviderName === AIRPROVIDERS.PORTBILET.TYPE;

export {
  updateVatDetails,
  deletePart,
  prepareVatDetails,
  clearVatDetails,
  preparedPriceDetails,
  prepareFunds,
  setRoutesDurationAndTime,
  isShowTaxes,
  isShowTaxesClue,
  getAvia,
  selectObj,
};
