import {
  NUMBERS,
  COLUMNS,
  PROVIDER,
  EXCEL,
  SOURCE_TYPE,
} from '../../constants/unloading1c';

let ACTIONS;

const DEFAULT = {
  listCompany: [],
  settings: {
    reportType: null,
    startDate: null,
    endDate: null,
    organizationType: null,
    isSmartagent: false,
    counteragentType: null,
    nomenclatureVatRate: null,
    comment: '',
    realizationSettings: {
      ownerType: null,
      groupingType: null,
      dayType: null,
    },
    fileImportSettings: {
      fileLink: '',
      excelSettings: {
        columnsVatRates: {},
        sheets: [],
        tripItemColumnName: SOURCE_TYPE.TRIP_ITEM_ID,
        tripItemSourceType: SOURCE_TYPE.TRIP_ITEM_ID,
      },
    },
    fileExportSettings: null,
    providerSourceType: null,
    principalInfoId: null,
  },
  error: null,
  principals: [],
  provider: null,
  disabledMonth: false,
  step: NUMBERS.ONE,
  workSheets: [],
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.UNLOADING1C.INFO_AMOUNTS_SUPPLIER: {
      const listCompany = action.listCompany;

      return {
        ...state,
        listCompany,
      };
    }

    case ACTIONS.UNLOADING1C.RESET: {
      return {
        ...DEFAULT,
      };
    }

    case ACTIONS.UNLOADING1C.SET_REPORT_TYPE: {
      const reportType = action.payload;

      if (reportType === NUMBERS.FIVE || reportType === NUMBERS.EIGHT) {
        return {
          ...state,
          settings: {
            ...state.settings,
            reportType,
            providerSourceType: PROVIDER,
            principalInfoId: NUMBERS.ZERO,
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          reportType,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_ERROR: {
      const error = action.payload;

      return {
        ...state,
        error,
      };
    }

    case ACTIONS.UNLOADING1C.SET_WORKSHEETS: {
      const workSheets = action.payload;

      return {
        ...state,
        workSheets,
      };
    }

    case ACTIONS.UNLOADING1C.SET_START_DATE: {
      const startDate = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          startDate,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_END_DATE: {
      const endDate = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          endDate,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_COMPANY: {
      const organizationType = action.payload;

      if (organizationType === NUMBERS.TWO) {
        return {
          ...state,
          settings: {
            ...state.settings,
            organizationType: NUMBERS.ONE,
            isSmartagent: true,
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          organizationType,
          isSmartagent: false,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_COUNTERAGENT: {
      const counteragentType = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          counteragentType,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_TYPE_IMPLEMENTATION: {
      const ownerType = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          realizationSettings: {
            ...state.settings.realizationSettings,
            ownerType,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_DISABLED_MONTH: {
      const disabledMonth = action.payload;

      return {
        ...state,
        disabledMonth,
      };
    }

    case ACTIONS.UNLOADING1C.SET_STEP: {
      const step = action.payload;

      return {
        ...state,
        step,
      };
    }

    case ACTIONS.UNLOADING1C.SET_GROUPING_TYPE: {
      const groupingType = action.payload;

      if (groupingType === NUMBERS.ZERO) {
        return {
          ...state,
          settings: {
            ...state.settings,
            realizationSettings: {
              ...state.settings.realizationSettings,
              groupingType,
            },
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          realizationSettings: {
            ...state.settings.realizationSettings,
            groupingType: NUMBERS.ONE,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_DAY_TYPE: {
      const dayType = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          realizationSettings: {
            ...state.settings.realizationSettings,
            dayType,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_AMOUNT_TYPE: {
      const amountType = action.payload;
      const type = `${COLUMNS.ONE}, ${COLUMNS.TWO}`;

      if (amountType === type) {
        return {
          ...state,
          settings: {
            ...state.settings,
            fileImportSettings: {
              ...state.settings.fileImportSettings,
              excelSettings: {
                ...state.settings.fileImportSettings.excelSettings,
                columnsVatRates: {
                  [COLUMNS.ONE]: NUMBERS.ZERO,
                  [COLUMNS.TWO]: NUMBERS.ONE,
                },
              },
            },
            nomenclatureVatRate: NUMBERS.SEVEN,
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            excelSettings: {
              ...state.settings.fileImportSettings.excelSettings,
              columnsVatRates: { [amountType]: null },
            },
          },
          nomenclatureVatRate: null,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_NOMENCLATURE: {
      const nomenclatureVatRate = action.payload;

      if (nomenclatureVatRate === NUMBERS.ZERO || nomenclatureVatRate === NUMBERS.ONE) {
        return {
          ...state,
          settings: {
            ...state.settings,
            fileImportSettings: {
              ...state.settings.fileImportSettings,
              excelSettings: {
                ...state.settings.fileImportSettings.excelSettings,
                columnsVatRates: Object.keys(state.settings.fileImportSettings.excelSettings.columnsVatRates).reduce((acc, key) => {
                  acc[key] = nomenclatureVatRate;
                  return acc;
                }, {}),
              },
            },
            nomenclatureVatRate,
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            excelSettings: {
              ...state.settings.fileImportSettings.excelSettings,
              columnsVatRates: Object.keys(state.settings.fileImportSettings.excelSettings.columnsVatRates).reduce((acc, key) => {
                acc[key] = NUMBERS.TWO;
                return acc;
              }, {}),
            },
          },
          nomenclatureVatRate,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_COMMENT: {
      const comment = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          comment,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_PROVIDER: {
      const provider = action.payload;

      if (provider === EXCEL) {
        return {
          ...state,
          provider,
          settings: {
            ...state.settings,
            providerSourceType: provider,
            principalInfoId: NUMBERS.ZERO,
          },
        };
      }

      return {
        ...state,
        provider,
        settings: {
          ...state.settings,
          providerSourceType: PROVIDER,
          principalInfoId: null,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_PRINCIPALS: {
      const principals = action.payload;

      return {
        ...state,
        principals,
      };
    }

    case ACTIONS.UNLOADING1C.SET_PRINCIPAL: {
      const principal = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          principalInfoId: principal,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_FILELINK: {
      const fileLink = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            fileLink,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_SHEET: {
      const sheet = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            excelSettings: {
              ...state.settings.fileImportSettings.excelSettings,
              sheets: sheet,
            },
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_TRIP_ITEM_COLUMN_NAME: {
      const tripItemColumnName = action.payload;

      if (!tripItemColumnName) {
        return {
          ...state,
          settings: {
            ...state.settings,
            fileImportSettings: {
              ...state.settings.fileImportSettings,
              excelSettings: {
                ...state.settings.fileImportSettings.excelSettings,
                tripItemColumnName: SOURCE_TYPE.TRIP_ITEM_ID,
                tripItemSourceType: SOURCE_TYPE.TRIP_ITEM_ID,
              },
            },
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            excelSettings: {
              ...state.settings.fileImportSettings.excelSettings,
              tripItemColumnName,
              tripItemSourceType: SOURCE_TYPE.TICKET_NUMBER,
            },
          },
        },
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('unloading1c', reducer, DEFAULT);
}
