let ACTIONS;

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.STOREINIT:
      // eslint-disable-next-line no-param-reassign
      state = {};
      break;

    case ACTIONS.USER.INIT:
      // eslint-disable-next-line no-param-reassign
      state.users = action.res;
      break;
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('User', reducer, {});
}
